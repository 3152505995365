@use 'assets/scss/mixins.scss' as mixins;

.Products {
    .form-group {
        margin-bottom: 10px;
    }
    .form-group {
        margin-bottom: 10px;
    }

    .form-group.no-margin {
        margin-bottom: 0;
    }

    .notes-and-variations {
        @include mixins.theme('background', 'ui-secondary-color');
        color: white;
        // margin: 0 -40px;
        text-align: center;
        font-size: 1em;
        font-weight: 600;
        border-radius: 8px;
        padding: 5px 0;
        margin-bottom: 15px;
    }

    .notes-and-variations.no-margin {
        margin-top: -14px;
    }

    .cbc-form .form-group label {
        font-weight: 600;
    }

    .product-images {
        margin-top: 38px;

        .imageContainer {
            height: initial !important;
            min-height: 110px;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .imageContainerInSizesTab {
            height: initial !important;
            min-height: 200px;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .itemFavourite {
            right: 0;
        }

        .product-inclusions {
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 0.8em;
            color: gray;
            margin-top: 15px;
            width: 11.5rem;
            @media (max-width: 480px)  {
                width: 17rem;
                margin-bottom: 1rem;
            }
        }

        .product-name,
        .manufacturer-description {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.8em;
            color: gray;
            margin-top: 15px;
            width: 10rem;
        }

        .product-name {
            @include mixins.theme('color', 'ui-primary-color');
            font-size: 0.8750em;
            font-weight: 600;
        }
    }

    .manufacturer-description {
        border: 1px solid;
        border-radius: 4px;
        background: var(--table_row_primary);
        padding: 5px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.8em;
        color: gray;
        margin-top: 15px;
        width: 10rem;
    }

    .manufacturer-description-sizes-tab {
        border: 1px solid;
        border-radius: 4px;
        background: var(--table_row_primary);
        padding: 5px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.8em;
        color: gray;
        margin-top: 15px;
        width: 12rem;
    }

    .manufacturer-description-column {
        margin-bottom: .8rem;
    }

    .nav-and-control {
        .control-buttons button {
            width: 100%;
        }
    }

    .searchGroup {
        margin-top: 0;
    }

    .buttons-container-shelf,
    .drillings-content {
        padding-left: 7.5px;
        padding-right: 7.5px;
        .button-blue {
            width: 100%;
            font-size: 0.85em;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .short-button {
            width: 50%;
        }

        .no-drillings {
            font-size: 0.85em;
            display: block;
            line-height: 1em;
        }

        .drilling-name {
            font-size: 0.85em;
            display: block;
            line-height: 1em;
            font-weight: 600;
            @include mixins.theme('color', 'ui-primary-color');
            padding-top: 10px;

            .btn-link {
                padding: 0;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .buttons-container-shelf button {
        max-height: 44px;
    }

    .buttons-container-shelf-row {
        margin: 8px -10px 20px -10px;
    }

    .product-form-fields {
        .alert-warning {
            margin: 0 0 15px 0 !important;
        }
    }

    .shelf {
        strong {
            @include mixins.theme('color', 'ui-primary-color');
            font-size: 0.8em;
            display: block;
            margin-right: 5px;
        }

        .shelf-actions .btn {
            padding: 0;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    .position {
        .cbc-number {
            input {
                font-size: 0.85em;
                padding: 0 0 0 0.5em;
            }
        }
    }

    .panelContainer {
        border: 2px solid;
        @include mixins.theme('border-color', 'ui-primary-color');
        display: flex;
        border-radius: 8px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;

        canvas {

        }
    }

    .Shelves {
        h3.notes-and-variations:not(.new) {
            border-bottom: 2px solid;
            @include mixins.theme('border-color', 'ui-primary-color');
            background: white !important;
            padding: 0 !important;
            border-radius: 0;

            span {
                color: white;
                @include mixins.theme('background', 'ui-primary-color');
                text-align: left;
                font-size: 0.8em;
                display: block;
                width: 150px;
                text-align: center;
                padding: 5px 15px;
                border-radius: 10px 10px 0 0;
            }
        }
    }

    .fieldset-title {
        font-size: 1.1rem !important;
        margin-bottom: .8rem;
        font-weight: 600;
    }

    .icon-thumbnail {
        padding: 0.25rem;
        background-color: #fff;
        border-radius: 0.25rem;
        max-width: 100%;
        height: auto;
    }
}

.cbc-popover.bs-popover-top {
    .arrow:after {
        border-top-color: #c92434 !important;
    }
}

.cbc-popover.bs-popover-left {
    .arrow:after {
        border-left-color: #c92434 !important;
    }
}

.cbc-popover.bs-popover-right {
    .arrow:after {
        border-right-color: #c92434 !important;
    }
}

.cbc-popover.bs-popover-bottom {
    .arrow:after {
        border-bottom-color: #c92434 !important;
    }
}

.cbc-popover {
    border: 0 !important;
    border-color: #c92434;
    z-index: 9999999 !important;

    .popover-body {
        background: #c92434;
        box-sizing: border-box;
        padding: 15px;
        border-radius: 8px;
        font-weight: 600;
        color: white;
        font-size: 12px;

        ul {
            margin: 0;
            padding: 0 0 0 15px;

            li {
                margin-bottom: 5px;
            }

            li:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.product .product-content {
    padding: 0;

    .cbc-form {
        padding-right: 0;
        padding-left: 0;

        .compound_title {
            @include mixins.theme('color', 'ui-secondary-color');
        }

        .dropdown.show {
            z-index: 2;
        }

        .drawer-runner:last-child .form-group:last-child .dropdown-menu {
            inset: auto 0px 0px 0px !important;
            transform: translate3d(0px, -37.6px, 0px) !important;
        }

        .drawer-thumbnail {
            @include mixins.theme('border-color', 'ui-primary-color');
            border: 2px solid;
            border-radius: 12px;
            padding: 0;
            margin: 0;
            max-width: 205px;
            max-height: 205px;
            margin: auto;

            img {
                border-radius: 12px !important;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .drawer-runner-preview {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;

            section {
                height: fit-content;
                margin-left: 15px;
            }
        }

        .cabinet-shape-container {
            position: relative;
            max-width: 100px;

            .cabinet-shape {
                max-width: 100px;
                border: 2px solid;
                @include mixins.theme('border-color', 'ui-primary-color');
                border-radius: 8px;
                height: 120px;
                display: flex;
                flex-direction: column;
            }

            .leg-container.left {
                left: 12px;

                .leg::after {
                    left: -4px;
                }
            }

            .leg-container.right {
                right: 12px;

                .leg::after {
                    right: -5px;
                }
            }

            .leg-container {
                position: absolute;
                bottom: -8px;
                z-index: -1;

                .leg {
                    background: rgb(131, 130, 130);
                    border: 1px solid rgb(31, 30, 30);
                    width: 5px;
                    height: 10px;
                    position: relative;
                }

                .leg::after {
                    background: rgb(131, 130, 130);
                    border: 1px solid rgb(31, 30, 30);
                    width: 12px;
                    height: 5px;
                    position: absolute;
                    content: '';
                    bottom: -2px;
                }
            }
        }
    }
    .cbc-wizard-content {
        padding: 15px 0;
    }
}

.Products.bottomMargin {
    margin-bottom: 205px;
}

hr {
    @include mixins.theme(
        'border-color',
        'ui-secondary-color-transparent',
        1
    ); //third param is importance, `1` adds !important after css property value
}

h2.recently-added-header {
    background: white !important;
    @include mixins.theme('color', 'ui-primary-color');
    text-align: left;
    position: relative;
    z-index: 3;
}

ul.drawer-runner-messages {
    padding: 0 0 0 15px;

    li.drawer-runner-message {
        border-bottom: 1px dashed #adadad;
        padding: 10px 0;
        margin-right: 15px;
    }
    li.drawer-runner-message:last-of-type {
        padding-bottom: 0;
        border: 0;
    }
}

.door-preview-opacity-toggle {
    display: flex;
    align-items: center;

    span.label {
        @include mixins.theme('color', 'ui-primary-color');
        font-weight: bold;
        font-size: 12px;
        margin-right: 10px;
        vertical-align: super;
    }

    input {
        width: 25px;
    }
}

.door-preview-note {
    font-size: 13px;
    font-style: italic;
    margin-top: 15px;
    color: #544343;
    box-shadow: none;
}

.product-inclusions-type {
    margin: 0;
    font-weight: 600;
}

.product-inclusions-body {
    margin-bottom: 5px;
    &.description {
        margin-bottom: .8rem;
    }
}

.product-inclusions-title {
    text-decoration: underline;
}

#notes-id, #variations-id {
    position: relative;
}

@media (max-width: 695px) {
    .hardware .product-content,
    .Products .product-content {
        padding: 0;
    }

    .hardware .hardwareItems .hardwareItem .details,
    .Products .hardwareItems .hardwareItem .details {
        padding: 0;
    }

    .hardware .hardwareItems .hardwareItem a,
    .Products .hardwareItems .hardwareItem a {
        word-break: break-all;
    }

    .product-content {
        .cbc-wizard-content {
            padding: 15px 20px 0 15px;

            .container {
                padding: 0;
            }

            .alert {
                border-radius: 8px;
                margin-bottom: 15px;
            }
        }

        .more-info {
            right: 15px;
        }

        .drawer-runner-preview {
            justify-content: center !important;
            padding: 0 15% 20px;
        }
    }

    .Products.bottomMargin {
        margin-bottom: 20px;
    }

    .Products .buttons-container-shelf .button-blue {
        width: 100%;
    }

    .Shelves {
        h3.notes-and-variations {
            span {
                width: fit-content !important;
            }
        }
    }

    .manufacturer-description {
        margin-bottom: 10px;
    }

    .nav-and-control {
        .control-buttons button img,
        .control-buttons a img {
            width: 20px !important;
        }
        span {
            font-size: 0.8em !important;
        }
    }

    .product-form-fields {
        margin: 0 !important;
        padding: 0 !important;
    }

    .colorSelection {
        .selection-box-before.horizontal {
            top: 45px !important;
            left: -50px !important;
        }

        .selection-box-after.horizontal {
            top: 50px !important;
            left: 34px !important;
        }
    }

    #variations-id {
        padding-bottom: 80px;
    }

    .buttons-container-shelf {
        font-size: 14px;
    }
}
